import React, { useEffect, useState } from 'react'
import BasePage from '../../component/layout/BasePage'
import AddressTable from './components/AddressTable'
import { fetchAddressesList } from '../../redux/actions/address'
import { useDispatch } from 'react-redux'
import LinkButton from '../../component/buttons/LinkButton'
import { deleteAddress } from '../../redux/actions/address'
import Toggle from '../../component/buttons/Toggle'
import MarkerMap from './components/MarkerMap'


function AddressList() {
    // page component for listing addresses 8
    // renders a table with address data
    // contains a delete button for each address

    const dispatch = useDispatch()
    const [addresses, setAddresses] = useState([])
    const [currentView, setCurrentView] = useState('map');

    const handleViewChange = (newView) => {
        setCurrentView(newView);
    };
   
    // fetch addresses from api, set them to state
    useEffect(() => {
        dispatch(fetchAddressesList())
            .then(res => {
                setAddresses(res)
            })
            .catch(
                err => {
                    console.log(err)
                }
            )
    }, []);



    const handleAddressDelete = (addressId) => {
        // dispatch event so api gets called
        dispatch(deleteAddress(addressId))

        // remove address from state
        const newAddresses = addresses.filter((address) => address.id !== addressId)
        setAddresses(newAddresses)
    }

    return (

        <BasePage>
            <section className="relative  active" id="features">
                <div className="flex flex-col">
                    <div className="d-flex justify-content-between align-items-center w-full">

                        <div className='mb-16 border-b-2'>
                            <h1 className="text-4xl font-bold">Address List</h1>

                        </div>
                        <div className="flex justify-between">

                            <Toggle onChange={handleViewChange} />

                            <LinkButton text="Add address" linkurl="/addressbook/create" />

                        </div>

                    </div>

                    <div className="overflow-x-auto">
                        <div className="p-1.5 w-full inline-block align-middle">
                            <div className="overflow-hidden border rounded-lg">
                                {currentView === 'map' ? (
                                    <MarkerMap data={addresses} />
                                ) : (

                                    <AddressTable addressData={addresses} onDeleteHandler={handleAddressDelete} />
                                )
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </BasePage>

    )
}



export default AddressList