import axiosInstance from "../../utils/axiosInstance";
import { createToast } from "./toast";

export const fetchAddressesList = () => () => {
    // action to fetch all addresses
    // returns a promise with the list of addresses

    return axiosInstance.get(
        "/api/addresses/"
    )
        .then((res) => {

            return res.data;
        })
}

export const fetchAddress = (addressId) => () => {
    // action to fetch an address
    // addressId is the id of the address to fetch
    // returns a promise with the address data

    return axiosInstance.get(
        `/api/addresses/${addressId}/`
    ).then((res) => {
        return res.data;
    })
}

export const deleteAddress = (addressId) => (dispatch) => {
    // action to delete an address
    // addressId is the id of the address to delete

    return axiosInstance.delete(
        `/api/addresses/${addressId}/`
    )
    .then((res) => {
        dispatch(createToast("Address deleted", "success"))
    })


}

export const addAddress = (address) => (dispatch) => {
    // action to add an address
    // postData is an object with address details
    // returns a promise with the added address data

    return axiosInstance.post(
        "/api/addresses/", address
    ).then((res) => {
        dispatch(createToast( "Address added", "success"))
        return res.data;
    })

}


export const editAddress = (addressId, address) => (dispatch) => {
    // action to edit an address
    // addressId is the id of the address to edit
    // postData is an object with address details
    // returns a promise with the edited address data

    return axiosInstance.put(
        `/api/addresses/${addressId}/`, address
    ).then((res) => {
        dispatch(createToast("Address edited", "success"))
        return res.data;
    })

}