import React, { useState, useEffect } from 'react'
import BasePage from '../../component/layout/BasePage'
import CarTable from './components/CarTable';
import axiosInstance from '../../utils/axiosInstance'
import LinkButton from '../../component/buttons/LinkButton';
import Card from './components/Card';
import TableCardToggle from '../../component/buttons/TableCardToggle';

function CarList() {
    // car list page main component
    // fetches car data from API and displays it using CarTable component
    // allows to switch between table and card view using TableCardToggle component

    const [cars, setCars] = useState([]);
    const [view, setView] = useState("table");


    useEffect(() => {
        axiosInstance.get(
            "/api/cars/"

        ).then((res) => {
            setCars(res.data);

        })
            .catch((err) => {
                console.log(err);
            })

    }, [])



    return (
        <BasePage>

            <div className=""></div>

            <section className="relative md:py-24 py-4 active" id="features">
                <div className="flex flex-col">
                    <div className="d-flex justify-content-between align-items-center w-full">
                        <div>
                            <h1 className="text-4xl font-bold">Cars</h1>

                        </div>
                        <div className="">
                            <TableCardToggle setView={setView} view={view} />
                        </div>
                        <div className="float-right">
                            <LinkButton text="Add car" linkurl="/cars/create/" />
                        </div>
                    </div>
                    <div className="">
                        {view === "table" ? (
                            <div className="overflow-x-auto">
                                <div className="p-1.5 w-full inline-block align-middle">
                                    <div className="overflow-hidden border rounded-lg">
                                        <CarTable data={cars} />
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="p-1.5 w-full inline-block align-middle">
                                <div className="flex flex-wrap gap-12" >
                                    {cars && cars.map((car) => (
                                        <Card
                                            title={car.name}
                                            license_plate={car.license_plate}
                                            imagesrc={car.image_thumbnail}
                                            active={car.active}
                                            linkurl={`/cars/${car.id}`}
                                        />
                                    ))}

                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </section>
        </BasePage>
    )
}

export default CarList