import React, { useEffect, useState } from 'react'
import Select from '../../../component/formfields/Select';
import Input from '../../../component/formfields/Input';
import SubmitButton from '../../../component/buttons/SubmitButton';
import ReturnButton from '../../../component/buttons/ReturnButton';
import axiosInstance from '../../../utils/axiosInstance';
import { useSelector } from 'react-redux';
import { createToast } from '../../../redux/actions/toast';

import Label from '../../../component/formfields/Label';
import DistancePreview from './DistancePreview';
import { FaChevronRight, FaChevronDown } from 'react-icons/fa';

import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import { nl } from "date-fns/locale"; // Import the Dutch locale

function RideForm({ addressOptions, carOptions, driverOptions }) {
    const user = useSelector(state => state.auth.user);

    // section to render a form to add a new ridepoint
    // used in RidepointCreate.jsx page component
    const [isMoreOptionsOpen, setIsMoreOptionsOpen] = useState(false);


    // data post format
    // {
    //     "startmilage": 12730,
    //     "endmilage": 12730,
    //     "startaddress": 4,
    //     "endaddress": 8,
    //     "car": 26,
    //     "driver": 4,
    //     "date": "2024-06-17T10:00:00Z",
    //     "reason": "Business meeting"
    // }
    const [startmilage, setStartmilage] = useState(0);
    const [endmilage, setEndmilage] = useState(0);
    const [startaddress, setStartaddress] = useState(null);
    const [endaddress, setEndaddress] = useState(null);
    const [car, setCar] = useState(null);
    const [driver, setDriver] = useState(null);
    const [date, setDate] = useState(new Date());
    const [rideReason, setRideReason] = useState('Visit customer');


    useEffect(() => {
        // update driver to logged in user
        setDriver(user?.id)

    }, [driverOptions])


    useEffect(() => {
        if (!car) return;

        // fetch latest ride, prefill form with data 
        axiosInstance.get(`/api/rides?car=${car}&latest=true`)
            .then(res => {
                const data = res.data[0];
                if (!data) return;

                setStartaddress(() => (data.endaddress))
                setStartmilage(data.endmilage)
            })
            .catch(err => {
                console.log(err);
            })
    }, [car])


    // handle form submit, dispatch form data to api
    // on success, redirect to ridepoints list
    const handleSubmit = (e) => {
        e.preventDefault();

        const postData = {
            startmilage: startmilage,
            endmilage: endmilage,
            startaddress: startaddress,
            endaddress: endaddress,
            car: car,
            driver: driver,
            date: date,
            reason: rideReason
        }

        axiosInstance.post('/api/rides/', postData)
            .then(res => {
                createToast('Ride created', 'success');
                window.location.href = '/rides';
            })
            .catch(err => {
                createToast('Failed to create ride', 'error');
            })

    }



    return (
        <form onSubmit={e => handleSubmit(e)}>
            <div className="flex flex-wrap -mx-3">

                {/* Car */}
                <div className="w-full md:w-3/4 px-3 md:mb-6 mt-6 md:mb-0">
                    <Select
                        name="car"
                        label="Car"
                        placeholder="Select car"
                        required
                        onChangeHandler={e => setCar(e.target.value)}
                        options={carOptions}
                        value={car}
                    />
                </div>

                {/* Date */}
                <div className="w-full md:w-1/4 px-3 md: mb-6 mt-6 md:mb-0">
                    <Label text="Date" />
                    <DatePicker
                        selected={date}
                        onChange={(d) => setDate(d)}
                        className="w-full px-4 py-2 mt-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                        locale={nl} // Set the locale to Dutch
                        dateFormat="yyyy/MM/dd" // Set your desired date format
                    />
                </div>

                <div className="w-full flex items-center justify-between">

                    <button
                        type="button"
                        onClick={() => setIsMoreOptionsOpen(!isMoreOptionsOpen)}
                        className="flex items-center ml-4 text-gray-500 hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        {isMoreOptionsOpen ? <FaChevronDown /> : <FaChevronRight />}
                        <span className="ml-1">More Options</span>
                    </button>


                </div>


                {isMoreOptionsOpen && (
                    <div className="w-full flex flex-wrap ">

                        <div className="w-full md:w-3/4 px-3 md:mb-6 mt-6 md:mb-0">
                            <Input
                                name="reason"
                                label="Ride reason"
                                type="text"
                                text=""
                                onChangeHandler={e => setRideReason(e.target.value)}
                                value={rideReason}
                            />
                        </div>

                        <div className="w-full md:w-1/4 px-3 md: mb-6 mt-6 md:mb-0">
                            <Select
                                name="driver"
                                label="Driver"
                                placeholder="Select driver"
                                onChangeHandler={e => setDriver(e.target.value)}
                                options={driverOptions}
                                value={driver}
                            />
                        </div>
                    </div>
                )}


                <div className="w-full md:w-1/2">
                    {/* start address point */}

                    <div className="w-full px-3 mb-6 mt-6 md:mb-0">

                        <Select
                            name="startaddress" label="Address" placeholder="Select start address"
                            required
                            onChangeHandler={e => setStartaddress(e.target.value)}
                            options={addressOptions}
                            value={startaddress}
                        />
                    </div>

                    {/* Milage */}
                    <div className="w-full px-3 mb-6 mt-6 md:mb-0">
                        <Input
                            name="startmilage"
                            label="Start milage"
                            placeholder="Enter start milage"
                            type="number"
                            required
                            onChangeHandler={e => setStartmilage(e.target.value)}
                            value={startmilage}
                        />
                    </div>
                </div>



                <div className="w-full md:w-1/2">
                    {/* start address point */}

                    <div className="w-full px-3 mb-6 mt-6 md:mb-0">
                        <Select
                            name="endaddress" label="End address" placeholder="Select end address"
                            required
                            onChangeHandler={e => setEndaddress(e.target.value)}
                            options={addressOptions}
                            value={endaddress}
                        />
                    </div>

                    {/* Milage */}
                    <div className="w-full px-3 mb-6 mt-6 md:mb-0">
                        <Input
                            name="endmilage"
                            label="End milage"
                            placeholder="Enter end milage"
                            type="number"
                            required
                            onChangeHandler={e => setEndmilage(e.target.value)}
                            value={endmilage}
                        />
                    </div>

                    {/* Distance */}
                    <div className="w-full px-3 mb-6 mt-6 md:mb-0">
                        <Label text="Distance" />

                        <DistancePreview start={startmilage} end={endmilage} />

                    </div>

                </div>

            </div >


            <div className="flex items-center justify-center mt-12">

                <SubmitButton text="Save" />
                <ReturnButton linkurl="/ridepoints" text="Cancel" />
            </div>
        </form >
    )
}

export default RideForm